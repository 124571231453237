<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="1" class="px-0" style="min-width:38px;">
                    <a class="edit-toggle pa-2" @click="toggleEdit()">
                        <v-icon>{{ edit ? 'mdi-message-bulleted-off' : 'mdi-message-bulleted' }}</v-icon>
                    </a>
                </v-col>

                <v-col v-if="edit">
                    <InputMask type="note"
                        v-on:edit-item-saved="itemSaved(arguments)"
                        :confirmable="false"
                        :inputData="displayContent"/>
                </v-col>

                <v-col v-else>
                    <v-row>
                        <v-col cols="4">
                            <div>
                                <v-icon color="red lighten-1" v-if="!displayContent.enabled">mdi-close</v-icon>
                                <strong>{{ level }}</strong>
                            </div>
                            <div>{{ localDate }}</div>
                            <div>{{ displayContent.user ? displayContent.user.displayname : 'Unknown' }}</div>
                        </v-col>
                        <v-col>
                            <span style="white-space: pre-line">{{ displayContent.description }}</span>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </v-card-text>
    </v-card>
</template>


<script>
import InputMask from '@/components/InputMaskTabs.vue'

const moment = require('moment-timezone')

export default {
    props: {
        id: Number,
        content: {
            default() {
                return {
                    description: '',
                    enabled: false,
                    type: 'note',
                    updatedAt: moment(),
                }
            },
        },
    },

    components: {
        InputMask,
    },

    data() {
        return {
            edit: false,
            displayContent: this.content,
        }
    },

    computed: {
        level() {
            return this.content.type[0].toUpperCase() + this.content.type.slice(1).toLowerCase()
        },

        localDate() {
            return moment(this.content.updatedAt).format('YYYY-MM-DD HH:mm')
        },
    },

    methods: {
        toggleEdit() {
            this.edit = !this.edit
        },
        itemSaved(item) {
            this.displayContent = item[0].data
            this.edit = false
        },
    },
}
</script>
