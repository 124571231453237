<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="1">
                    <a class="edit-toggle pa-3" @click="toggleEdit()">
                        <v-icon>{{ edit ? 'mdi-message-bulleted-off' : 'mdi-message-bulleted' }}</v-icon>
                    </a>
                </v-col>

                <v-col v-if="edit">
                    <InputMask :type="type"
                        v-on:edit-item-saved="itemSaved(arguments)"
                        :confirmable="false"
                        :inputData="displayContent"/>
                </v-col>

                <v-col v-else>
                    <template v-if="type != 'note'">
                        <v-row v-for="(item, i) in dataKeys"
                                :key="i">

                            <v-col cols="3">
                                {{ item.name }}
                            </v-col>

                            <v-col v-if="item.type && item.type === 'link'">
                                <v-btn text :href="displayContent[item.key]" target="_blank">
                                    <span id="router-link">
                                        {{ displayContent[item.key] }}
                                    </span>
                                </v-btn>
                                <v-btn icon small @click="copyContent('router-link')">
                                    <v-list-item-icon class="mr-0">
                                        <v-icon size="small">mdi-content-copy</v-icon>
                                    </v-list-item-icon>
                                </v-btn>
                            </v-col>

                            <v-col v-else>
                                {{ displayContent[item.key] }}
                            </v-col>
                        </v-row>
                    </template>

                    <template v-else>
                        <v-row>
                            <v-col cols="4">
                                <div>
                                    <v-icon color="red lighten-1" v-if="!displayContent.enabled">mdi-close</v-icon>
                                    <strong>{{ level }}</strong>
                                </div>
                                <div>{{ date }}</div>
                                <div>{{ displayContent.user ? displayContent.user.displayname : 'Unknown' }}</div>
                            </v-col>
                            <v-col>
                                {{ displayContent.description }}
                            </v-col>
                        </v-row>
                    </template>

                </v-col>

            </v-row>
        </v-card-text>
    </v-card>
</template>


<script>
import { mapActions } from 'vuex'
import InputMask from '@/components/InputMaskTabs.vue'

const moment = require('moment-timezone')

export default {
    props: {
        id: Number,
        content: {
            default() {
                return {}
            },
        },
        type: String,
    },

    components: {
        InputMask,
    },

    data() {
        return {
            edit: false,
            displayContent: this.content,
            dataKeys: [],
        }
    },

    computed: {
        level() {
            return this.displayContent.type ? this.displayContent.type[0].toUpperCase() + this.displayContent.type.slice(1).toLowerCase() : ''
        },
        date() {
            return moment(this.displayContent.updatedAt).format('YYYY-MM-DD HH:mm')
        },
    },

    methods: {
        ...mapActions({
            showSnackbar: 'page/showSnackbar',
            setSnackbar: 'page/setSnackbar',
        }),

        toggleEdit() {
            this.edit = !this.edit
        },

        itemSaved(item) {
            this.displayContent = item[0].data
            this.edit = false
        },

        copyContent(copyData) {
            const copyText = document.getElementById(copyData)
            navigator.clipboard.writeText(copyText.textContent)

            this.setSnackbar({
                type: 'success',
                text: `${this.$t('Link copied!')}`,
            })
            this.showSnackbar(true)
        },
    },

    created() {
        this.displayContent = this.content

        switch (this.type) {
        case 'computer':
            this.dataKeys = [
                {
                    key: 'name',
                    name: 'Name',
                },
                {
                    key: 'lastMaintain',
                    name: 'Maintenance Date',
                },
                {
                    key: 'MKLapseVer',
                    name: 'Software Version',
                },
                {
                    key: 'Teamviewer',
                    name: 'Teamviewer Id',
                },
                {
                    key: 'checkDate',
                    name: 'Check Date',
                },
                {
                    key: 'intHddFree',
                    name: 'Internal Free Disk Space',
                },
                {
                    key: 'intHddTotal',
                    name: 'Internal Total Disk Space',
                },
                {
                    key: 'extHddFree',
                    name: 'External Free Disk Space',
                },
                {
                    key: 'extHddTotal',
                    name: 'External Total Disk Space',
                },
                {
                    key: 'hotspot',
                    name: 'Hotspot',
                },
            ]
            break

        case 'router':
            this.dataKeys = [
                {
                    key: 'vpn',
                    name: 'VPN',
                    type: 'link',
                },
            ]
            break

        case 'camera':
            this.dataKeys = [
                {
                    key: 'shutterCount',
                    name: 'Shutter Count',
                },
            ]
            break

        case 'housing':
            this.dataKeys = [
                {
                    key: 'version',
                    name: 'Version',
                },
                {
                    key: 'color',
                    name: 'Color',
                },
            ]
            break

        case 'lens':
            this.dataKeys = [
                {
                    key: 'model',
                    name: 'Model',
                },
                {
                    key: 'min',
                    name: 'Minimum',
                },
                {
                    key: 'max',
                    name: 'Maximum',
                },
                {
                    key: 'steps',
                    name: 'Steps',
                },
            ]
            break

        default:
            break
        }
    },

    watch: {
        id(val) {
            this.displayContent = this.content
        },
    },
}
</script>
