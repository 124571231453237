import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"1"}},[_c('a',{staticClass:"edit-toggle pa-3",on:{"click":function($event){return _vm.toggleEdit()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.edit ? 'mdi-message-bulleted-off' : 'mdi-message-bulleted'))])],1)]),(_vm.edit)?_c(VCol,[_c('InputMask',{attrs:{"type":_vm.type,"confirmable":false,"inputData":_vm.displayContent},on:{"edit-item-saved":function($event){return _vm.itemSaved(arguments)}}})],1):_c(VCol,[(_vm.type != 'note')?_vm._l((_vm.dataKeys),function(item,i){return _c(VRow,{key:i},[_c(VCol,{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.type && item.type === 'link')?_c(VCol,[_c(VBtn,{attrs:{"text":"","href":_vm.displayContent[item.key],"target":"_blank"}},[_c('span',{attrs:{"id":"router-link"}},[_vm._v(" "+_vm._s(_vm.displayContent[item.key])+" ")])]),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.copyContent('router-link')}}},[_c(VListItemIcon,{staticClass:"mr-0"},[_c(VIcon,{attrs:{"size":"small"}},[_vm._v("mdi-content-copy")])],1)],1)],1):_c(VCol,[_vm._v(" "+_vm._s(_vm.displayContent[item.key])+" ")])],1)}):[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c('div',[(!_vm.displayContent.enabled)?_c(VIcon,{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-close")]):_vm._e(),_c('strong',[_vm._v(_vm._s(_vm.level))])],1),_c('div',[_vm._v(_vm._s(_vm.date))]),_c('div',[_vm._v(_vm._s(_vm.displayContent.user ? _vm.displayContent.user.displayname : 'Unknown'))])]),_c(VCol,[_vm._v(" "+_vm._s(_vm.displayContent.description)+" ")])],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }